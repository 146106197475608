.container-sections-auths {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.container-gray {
  background-color: @gray;
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .logo {
    height: 70%;
  }
}
.container-gray-sm {
  background-color: @gray;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .logo {
    height: 70%;
  }
}
.container-color {
  height: 100%;
  width: 100%;
  position: relative;
}
.container-inner-color {
  position: absolute;
  height: 94%;
  width: 90%;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .container-image {
    position: relative;
    top: -20px;
  }
}
.img-girl-boy {
  height: 250px;
}
.border-left {
  border-left: 5px solid @primary;
}

// Login
.logo-eco {
  width: 80%;
}
.container-image-style {
  position: relative;
  width: 100%;
  .curves {
    position: absolute;
    width: 100%;
    top: -275px;
    user-select: none !important;
    z-index: 5;
  }
  .img {
    position: absolute;
    width: 450px;
    margin-left: 50%;
    left: -225px;
    top: -80px;
    z-index: 20;
  }
  .img-girl {
    position: absolute;
    width: 30%;
    margin-left: 50%;
    left: -150px;
    top: -50px;
    z-index: 20;
  }
}

// Register
.genre-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  label {
    flex: auto;
  }
}
.btn-green {
  background-color: #A6FBA2 !important;
  border-color: #A6FBA2 !important;
  color: white !important;
}
.btn-turquesa {
  background-color: #AFF7EE !important;
  border-color: #AFF7EE !important;
  color: white !important;
}
