.container-avatar {
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  border: 5px solid @primary;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.career-container-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .point-career {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: @secondary;
    margin-bottom: 8px;
    margin-right: 10px;
  }
}
