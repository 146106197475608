.container-navbar {
  display: flex;
  flex-direction: row;
}
.item-menu {
  color: white;
  cursor: pointer;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-right: 2px solid white;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: @secondary;
    border-right: 2px solid @secondary;
    transition: all 200ms ease-in;
  }
}
.menu-active {
  background-color: @secondary;
  border-right: 2px solid @secondary;
  transition: all 200ms ease-in;
}