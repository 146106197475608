.btn-wen {
  color: white;
  background-color: @primary-wen;
  border-color: @primary-wen;
  &:hover {
    border-color: @primary-wen;
    color: @primary-wen;
  }
}
.btn-dark {
  color: white;
  background-color: @dark-gray;
  border-color: @dark-gray;
  &:hover {
    border-color: @dark-gray;
    color: @dark-gray;
  }
}
.btn-section {
  height: 50px;
  padding: 0 1rem;
}
.btn-section-one {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.btn-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
