.avatar {
  height: 120px;
  width: 120px;
  background-color: white;
  border-radius: 50%;
  border: 8px solid @success;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-profile {
  position: relative;
  overflow: hidden;
}
.container-line-graph {
  width: 100%;
  height: 18px;
  position: relative;
  background-color: @gray;
  overflow: hidden;
  .line-graph-color {
    position: absolute;
    height: 30px;
    top: -3px;
    left: 0;
    z-index: 10;
  }
}
.btn-contianer {
  position: relative;
  margin-bottom: 1rem;
  .btn-avatar {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
}
.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
