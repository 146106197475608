@font-face {
  font-family: Montserrat;
  src: url(assets/fonts/Montserrat-Regular.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: white !important;
}

* {
  box-sizing: border-box !important;
}

.line-height-small {
  line-height: 1 !important;
}

@import '~antd/dist/antd.less';
@import "assets/styles/variables";
@import "assets/styles/buttons";
@import "assets/styles/commons";
@import "assets/styles/divider";
@import "assets/styles/public";
@import "assets/styles/private";
@import "assets/styles/navbar";
@import "assets/styles/admin";

@primary-color: #0B5580;